import axios from "axios";
import Transcription from "@/types/Transcription";

class TranscriptionDataService {
  // eslint-disable-next-line
  getAll(): Promise<any> {
    return axios
      .get('https://gitlab.com/api/v4/projects/33474676/repository/files/transcriptions_pour_orgue.csv/raw')
      .then((response) => {
        const transcriptionsResults = [] as Transcription[];

        const csvString = response.data;
        const delimiter = ";";

        const rows = csvString.slice(csvString.indexOf("\n") + 1).split("\n");
        rows.pop();

        rows.map(function (row: string) {
          const values = row.split(delimiter);

          const gitlabRepositoryAbsolutePath = 'https://gitlab.com/psautier-de-sainte-lucie/psautier-donnees/-/raw/main';
          const filename = values[0];
          const title = values[1];
          const composer = values[2];
          const numberOfPages = Number(values[3]);

          const pngAbsolutePath = [''];
          for (let index = 0; index < numberOfPages; index++) {
            pngAbsolutePath[index] = gitlabRepositoryAbsolutePath + '/transcriptions-png/' + filename + '-' + (index + 1) + '.png';
          }

          const pdfAbsolutePath = gitlabRepositoryAbsolutePath + '/transcriptions-pdf-mscz/' + filename + '.pdf';
          const msczAbsolutePath = gitlabRepositoryAbsolutePath + '/transcriptions-pdf-mscz/' + filename + '.mscz';
          const hasPolyphonieAudioFile = values[4].toLowerCase() == 'true';
          const polyphonieAbsolutePath = gitlabRepositoryAbsolutePath + '/transcriptions-audio/' + filename + '-p' + '.mp3';
          const hasSopranoAudioFile = values[5].toLowerCase() == 'true';
          const sopranoAbsolutePath = gitlabRepositoryAbsolutePath + '/transcriptions-audio/' + filename + '-s' + '.mp3';
          const hasAltoAudioFile = values[6].toLowerCase() == 'true';
          const altoAbsolutePath = gitlabRepositoryAbsolutePath + '/transcriptions-audio/' + filename + '-a' + '.mp3';
          const hasTenorAudioFile = values[7].toLowerCase() == 'true';
          const tenorAbsolutePath = gitlabRepositoryAbsolutePath + '/transcriptions-audio/' + filename + '-t' + '.mp3';
          const hasBasseAudioFile = values[8].toLowerCase() == 'true';
          const basseAbsolutePath = gitlabRepositoryAbsolutePath + '/transcriptions-audio/' + filename + '-b' + '.mp3';
          const isExpanded = false;

          const newTranscription = {
            filename,
            title,
            composer,
            numberOfPages,
            pngAbsolutePath,
            pdfAbsolutePath,
            msczAbsolutePath,
            hasPolyphonieAudioFile,
            polyphonieAbsolutePath,
            hasSopranoAudioFile,
            sopranoAbsolutePath,
            hasAltoAudioFile,
            altoAbsolutePath,
            hasTenorAudioFile,
            tenorAbsolutePath,
            hasBasseAudioFile,
            basseAbsolutePath,
            isExpanded,
          } as Transcription;
          transcriptionsResults.push(newTranscription);
        });

        return { data: transcriptionsResults };
      })
      // eslint-disable-next-line
      .catch(error => console.log(error));
  }
}

export default new TranscriptionDataService();
